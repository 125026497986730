<template>
    <v-row class="ma-auto">
        <v-col cols="12">
            <heading>店舗一覧・アクセス</heading>
        </v-col>
        <!-- 葛西エリア -->
        <v-col cols="6">
            <router-link
                class="text-decoration-none"
                :to="{ name: 'Access12' }"
            >
                <thumbnail
                    :image="kasaiEkimae"
                    gymName="葛西駅前店"
                    description="葛西駅メトロセンター出口から徒歩1分!"
                ></thumbnail>
            </router-link>
        </v-col>
        <v-col cols="6">
            <router-link class="text-decoration-none" :to="{ name: 'Access6' }">
                <thumbnail
                    :image="kasaiKannana"
                    gymName="葛西環七通り店"
                    description="東西線葛西駅徒歩9分！"
                ></thumbnail>
            </router-link>
        </v-col>

        <!-- 浦安エリア -->
        <v-col cols="6">
            <router-link
                class="text-decoration-none"
                :to="{ name: 'Access10' }"
            >
                <thumbnail
                    :image="urayasuEkimae"
                    gymName="浦安駅前店"
                    description="東西線浦安駅徒歩30秒！"
                ></thumbnail>
            </router-link>
        </v-col>
        <v-col cols="6">
            <router-link
                class="text-decoration-none"
                :to="{ name: 'Access16' }"
            >
                <thumbnail
                    :image="urayasuKitaSakae"
                    gymName="浦安北栄店"
                    description="東西線浦安駅徒歩6分！"
                ></thumbnail>
            </router-link>
        </v-col>

        <!-- 南行徳エリア -->
        <v-col cols="6">
            <router-link class="text-decoration-none" :to="{ name: 'Access3' }">
                <thumbnail
                    :image="minamiGyotokuEkimae"
                    gymName="南行徳駅前店"
                    description="東西線南行徳駅徒歩30秒！"
                ></thumbnail>
            </router-link>
        </v-col>
        <v-col cols="6">
            <router-link class="text-decoration-none" :to="{ name: 'Access8' }">
                <thumbnail
                    :image="minamiGyotokuSocola"
                    gymName="南行徳SOCOLA店"
                    description="東西線南行徳駅徒歩8分！"
                ></thumbnail>
            </router-link>
        </v-col>
        <v-col cols="6">
            <router-link
                class="text-decoration-none"
                :to="{ name: 'Access14' }"
            >
                <thumbnail
                    :image="minamiGyotokuArai"
                    gymName="南行徳新井店"
                    description="東西線南行徳駅徒歩12分!"
                ></thumbnail>
            </router-link>
        </v-col>

        <!-- 行徳エリア -->
        <v-col cols="6">
            <router-link class="text-decoration-none" :to="{ name: 'Access' }">
                <thumbnail
                    :image="gyotokuEkimae"
                    gymName="行徳駅前店"
                    description="東西線行徳駅徒歩1分！"
                ></thumbnail>
            </router-link>
        </v-col>
        <v-col cols="6">
            <router-link class="text-decoration-none" :to="{ name: 'Access7' }">
                <thumbnail
                    :image="gyotokuminami"
                    gymName="行徳駅前南店"
                    description="東西線行徳駅徒歩2分！"
                ></thumbnail>
            </router-link>
        </v-col>
        <v-col cols="6">
            <router-link
                class="text-decoration-none"
                :to="{ name: 'Access13' }"
            >
                <thumbnail
                    :image="gyotokuEkimae1Chome"
                    gymName="行徳駅前一丁目店"
                    description="行徳駅徒歩5分！"
                ></thumbnail>
            </router-link>
        </v-col>

        <!-- 妙典エリア -->
        <v-col cols="6">
            <router-link class="text-decoration-none" :to="{ name: 'Access4' }">
                <thumbnail
                    :image="myodenEaonmae"
                    gymName="妙典イオン前店"
                    description="妙典イオン(フードコート出口)から徒歩20秒！"
                ></thumbnail>
            </router-link>
        </v-col>
        <v-col cols="6">
            <router-link class="text-decoration-none" :to="{ name: 'Access2' }">
                <thumbnail
                    :image="myodenSuehiro"
                    gymName="妙典末広店"
                    description="東西線妙典駅徒歩10分（専用駐車場あり）！"
                ></thumbnail>
            </router-link>
        </v-col>
        <v-col cols="6">
            <router-link class="text-decoration-none" :to="{ name: 'Access5' }">
                <thumbnail
                    :image="myodenShioyaki"
                    gymName="妙典塩焼店"
                    description="東西線妙典駅徒歩5分！"
                ></thumbnail>
            </router-link>
        </v-col>
        <v-col cols="6">
            <router-link
                class="text-decoration-none"
                :to="{ name: 'Access11' }"
            >
                <thumbnail
                    :image="myoden3chome"
                    gymName="妙典三丁目店"
                    description="東西線妙典駅徒歩5分！"
                ></thumbnail>
            </router-link>
        </v-col>

        <!-- 平井エリア -->
        <v-col cols="6">
            <router-link class="text-decoration-none" :to="{ name: 'Access9' }">
                <thumbnail
                    :image="hiraiEkimae"
                    gymName="平井蔵前橋通り店"
                    description="JR総武線平井駅徒歩3分！"
                ></thumbnail>
            </router-link>
        </v-col>
        <v-col cols="6">
            <router-link
                class="text-decoration-none"
                :to="{ name: 'Access15' }"
            >
                <thumbnail
                    :image="hiraiYurinokihashidori"
                    gymName="平井ゆりのき橋通り店"
                    description="JR総武線平井駅徒歩6分！"
                ></thumbnail>
            </router-link>
        </v-col>

        <v-col cols="12">
            <div class="text-body-1 ma-2">
                ジムは自宅から近くて通いやすいがベスト。
            </div>
        </v-col>
    </v-row>
</template>

<script>
import Heading from '@/components/Heading.vue'
import Thumbnail from '@/components/gyms/Thumbnail.vue'
export default {
    // data() {
    //     return {
    //         gyms: [
    //             { id: 1, name: '行徳駅前店' },
    //             { id: 6, name: '行徳駅前南店' },
    //             { id: 2, name: '南行徳駅前店' },
    //             { id: 7, name: '南行徳SOCOLA店' },
    //             { id: 3, name: '妙典イオン前店' },
    //             { id: 4, name: '妙典末広店' },
    //             { id: 5, name: '妙典塩焼店' },
    //         ],
    //     }
    // },
    components: {
        Heading,
        Thumbnail,
    },
    methods: {
        imagePlace(n) {
            return '/static/pc_top/top_gym0' + n + '.png'
        },
    },
    computed: {
        gyotokuEkimae() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_gyms/IMG_1494.png'
                : 'static/sp_gyms/gyms_img_gyotoku.png'
        },
        minamiGyotokuEkimae() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_gyms/IMG_3182.png'
                : 'static/sp_gyms/gyms_img_minami.png'
        },
        myodenEaonmae() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_gyms/7.png'
                : 'static/sp_gyms/gyms_img_myodenion.png'
        },
        myodenSuehiro() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_gyms/IMG_1582.png'
                : 'static/sp_gyms/gyms_img_myodensuehiro.png'
        },
        myodenShioyaki() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_gyms/shioyaki7.png'
                : 'static/sp_gyms/gyms_img_myodenshioyaki.png'
        },
        kasaiKannana() {
            return 'static/pc_gyms/葛西看板-squashed.png'
        },
        gyotokuminami() {
            return 'static/pc_gyms/gyotokuminami-1.png'
        },
        minamiGyotokuSocola() {
            return 'static/pc_gyms/socola-1.png'
        },
        hiraiEkimae() {
            return 'static/pc_access_9/Access9_8_2.jpg'
        },
        urayasuEkimae() {
            return 'static/pc_access_10/urayasu_hero.jpg'
        },
        myoden3chome() {
            return 'static/pc_access_11/Myoden3Chome-02.JPG'
        },
        kasaiEkimae() {
            return 'static/pc_access_12/kasaiekimae-6.jpg'
        },
        gyotokuEkimae1Chome() {
            return 'static/pc_access_13/gyotokuekimae1chome_8.png'
        },
        minamiGyotokuArai() {
            return 'static/pc_access_14/minamigyotokuarai-9.png'
        },
        hiraiYurinokihashidori() {
            return 'static/pc_access_15/IMG_2137-hiraiyurinokibashidori-006.png'
        },
        urayasuKitaSakae() {
            return 'static/pc_access_16/Picture9_UrayasuKitaSakae.jpg'
        },
    },
}
</script>

<style lang="scss" scoped></style>
